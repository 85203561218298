import clsx from 'clsx';
import { get, sumBy } from 'lodash';
import React, { useContext } from 'react';
import CartContext from '../../../context/CartProvider';
import Button from '../../atoms/Button/Button';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';
import CouponForm from './CouponForm';
import ShippingInfo from './ShippingInfo';
import { Box, Button as CButton, Center, Flex, Image, Text, useToast } from '@chakra-ui/react';
import { Link } from 'gatsby';
import { useCartStore } from 'stores/cart';
import { useGlobalStore } from 'stores/global';
import Icon from '../../atoms/Icon/Icon';
import CustomService from './CustomService';
import * as pcStyles from './CartSummary.module.css';
import * as mpsStyles from './CartSummary.mps.module.scss';
import { storeStyle, isMadPawsStore } from '../../../common/util';
import ShippingFree from '../ShippingFree/ShippingFree';

const styles = storeStyle({ mpsStyles, pcStyles });
const CartSummary = ({ cartType, onClick, nextStepValid, cartStep, cartFunc, isCheckout }) => {
  const ctx = useContext(CartContext);
  const cart = get(ctx, 'state.cart', {});
  const checkout = get(ctx, 'state.checkout', {});
  const { currency, baseAmount, cartAmount, numberItems } = cart;

  const { service, updatingCartItem } = useCartStore(state => state);
  const { isAutoShip, frequencySelected, toggleAutoship } = useGlobalStore(state => state);

  const toast = useToast();

  // const checkoutAmount = baseAmount || cartAmount;
  const taxTotal = (checkout && 'tax_total' in checkout && checkout.tax_total) || cartAmount / 11; // GST value not provided in cart

  const totalOriginalPrice = sumBy(
    cart.lineItems.physical_items,
    line => line.original_price * line.quantity
  );
  const totalListPrice = sumBy(cart.lineItems.physical_items, line => line.extended_list_price);
  const totalSavings = totalOriginalPrice - totalListPrice + (baseAmount - cartAmount);

  if (!numberItems) {
    return null;
  }

  const checkoutClickHandler = () => {
    if (toggleAutoship && frequencySelected && !isAutoShip) {
      toast({
        description: (
          <>
            AutoShip product is required. <br /> Please select an AutoShip product
          </>
        ),
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      });
    } else if (toggleAutoship && !frequencySelected) {
      toast({
        description: (
          <>
            Frequency is required. <br />
            Please select AutoShip frequency
          </>
        ),
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      });
    } else {
      cartFunc('account');
    }
  };

  if (cartType === 'full') {
    return (
      <Box className={styles.bcCartSummary} mt={isCheckout ? 10 : 0}>
        <div className={styles.bcCartSummaryTop}>
          <Flex pb={6} justify={'space-between'} className={styles.bcCartSummaryTitle}>
            <p>Order summary</p>
            {isCheckout && (
              <CButton
                color={isMadPawsStore() ? 'var(--primary-default)' : '#14607A'}
                fontSize={14}
                variant={'link'}
                leftIcon={<Icon symbol={'chevLeft'} />}
                onClick={() => cartFunc('cart')}
              >
                Edit Cart
              </CButton>
            )}
          </Flex>
          {isCheckout && (
            <Box>
              {cart?.lineItems?.physical_items?.map((item, index) => (
                <Flex
                  borderBottom={'1px solid #D5DFE9'}
                  key={index}
                  className={styles.bcCartSummaryItem}
                  justify={'space-between'}
                >
                  <Flex gap={4}>
                    <Image
                      m={0}
                      maxW={'63'}
                      objectFit={'contain'}
                      src={item?.image_url}
                      alt={item?.name}
                    />
                    <Box>
                      <Text fontSize={14} maxW={134}>
                        {item.name}
                      </Text>
                      <Text
                        color={isMadPawsStore() ? 'var(--greyscale-dark-2x)' : '#5D6975w'}
                        fontSize={14}
                      >
                        Qty: {item.quantity}
                      </Text>
                    </Box>
                  </Flex>
                  <Text fontSize={14}>${item?.list_price}</Text>
                </Flex>
              ))}
            </Box>
          )}
          <div>
            <div className={clsx('flex-between', styles.bcCartSummaryItem)}>
              <span className={styles.bcCartSummaryItemLabel}>Subtotal</span>
              <span className={styles.bcCartSummaryItemValue}>
                <CurrencyFormatter
                  appendZero={true}
                  currency={currency.code}
                  amount={totalOriginalPrice}
                />
              </span>
            </div>

            <div className={clsx('flex-between', styles.bcCartSummaryItem)}>
              <span className={styles.bcCartSummaryItemLabel}>Savings</span>
              <span className={`${styles.bcCartSummaryItemValue} ${styles.totalSavings}`}>
                -
                <CurrencyFormatter
                  appendZero={true}
                  currency={currency.code}
                  amount={totalSavings}
                />
              </span>
            </div>

            {isCheckout && (
              <div className={styles.bcCartSummaryItem}>
                <ShippingInfo checkout={checkout} />
              </div>
            )}

            <div
              className={clsx(
                'flex-between',
                styles.bcCartSummaryItem,
                styles.bcCartSummaryItemLast
              )}
            >
              <span className={styles.bcCartSummaryItemLabel}>GST</span>
              <span className={styles.bcCartSummaryItemValue}>
                <CurrencyFormatter appendZero={true} currency={currency.code} amount={taxTotal} />
              </span>
            </div>

            {/* Gift Certificate */}
            {/* <div className={styles.bcCartSummaryItem}>
            <GiftCertificate />
          </div> */}
          </div>

          {/* Coupon Codes */}
          <div className={styles.bcCartSummaryItem}>
            <CouponForm />
          </div>
          {!isCheckout && <CustomService />}
          <div className={styles.shippingFree}>
            <ShippingFree totalAmount={checkout.grand_total ?? cartAmount} />
          </div>
          <div className={clsx('flex-between', styles.bcCartSummaryItemTotal)}>
            <span className={styles.bcCartSummaryItemTotalLabel}>Total ({currency.code})</span>
            <span className={styles.bcCartSummaryItemTotalLabel}>
              <CurrencyFormatter
                appendZero={true}
                currency={currency.code}
                amount={checkout.grand_total ?? cartAmount}
              />
            </span>
          </div>
          <div className={clsx('flex-end', styles.bcCartCheckoutButton)}>
            {cartStep && (
              <Button
                level="primary"
                type="button"
                disabled={!nextStepValid || service || updatingCartItem}
                onClick={checkoutClickHandler}
              >
                Checkout
              </Button>
            )}
            {/* {!cartStep && (
            <Button level="primary" href="/checkout">
              Proceed to Checkout
            </Button>
          )} */}
          </div>
          {!isCheckout && (
            <Center gap={2} mt={4} color={isMadPawsStore() ? 'var(--primary-default)' : '#4B5661'}>
              <Icon symbol={'chevLeft'} />
              <Text
                as={Link}
                to="/"
                color={isMadPawsStore() ? 'var(--primary-default)' : ''}
                fontSize={14}
                className="font-medium underline"
                _hover={{ color: '#1D6464', textDecorationColor: '#1D6464 !important' }}
              >
                Continue Shopping
              </Text>
            </Center>
          )}
        </div>
        {/* {!isCheckout && <FreeShipping />} */}
      </Box>
    );
  }

  return (
    <div className={styles.bcMiniCartSummary}>
      <div data-mini-subtotal className="flex-between">
        <span>Subtotal</span>
        <span>
          <CurrencyFormatter currency={currency.code} amount={cartAmount} />
        </span>
      </div>
      <div className={styles.buttonWrapp}>
        <Button level="primary" href="/cart" onClick={onClick}>
          <span>View Cart</span>
        </Button>
        {/* <FastCheckout cart_id={cart.cartId} /> */}
        <Button level="secondary" href="/checkout">
          Checkout
        </Button>
      </div>
    </div>
  );
};

export default CartSummary;
